import React from "react";
import '../App.css';
import '../styles/Navbar.css'
import logo from '../assets/images/logo.png'

const Navbar = () =>{
    return(
        <nav className="flex-column">
        <ul className="flex ">
            <li className="logo-container flex"><img src={logo}/><h1 className="no-margin flex">EnablePlumbing</h1></li>
     
            
        </ul>
        </nav>
    )
}

export default Navbar