import React,{useState, useEffect, useRef} from "react";
import '../styles/Features.css'

const FeatureBox = (props) =>{
    const [isVisible, setIsVisible] = useState(false);
    const cardRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              setIsVisible(true);
            }
          },
          { threshold: 0.1 }
        );
    
        if (cardRef.current) {
          observer.observe(cardRef.current);
        }
    
        return () => {
          if (cardRef.current) {
            observer.unobserve(cardRef.current);
          }
        };
      }, []);
    return(
        <div ref={cardRef} id="feature-card" className={`card ${isVisible ? 'card-visible' : ''} flex-column align-center`}>
            <div id="card-content" className="flex-column align-center">
                <div className="img-container">
                <img src={props.icon}/>
                </div>
                <h3 className="no-margin">{props.title}</h3>
                <p id="feature-detail" className="">{props.detail}</p>

            </div>
     
        </div>
    )
}

export default FeatureBox;