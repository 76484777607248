import React, { useContext, useRef } from "react";
import useFields from '../hooks/useFields';
import '../styles/PopupForm.css'
import AuthContext from "../context/AuthContext";
import CloseIcon from "./CloseIcon";

const ActionForm = () =>{
    const data = useRef();
    const INITIAL_DATA = {
        fname:'',
        lname:'',
        email:'',
        phoneNumber:''

    }

    const scriptURL = 'https://script.google.com/macros/s/AKfycbxWMFg7GSVxlPRj1B2Yse2mAZDJUHpue6Q-dYCjaaZcZUpgypOZh-Brw4E_32U0bK8A/exec'
    

    const handleSubmit = (e) =>{
        e.preventDefault()
        fetch(scriptURL, {method:'POST', body: new FormData(data.current)})
        .then(response => alert("Submit Successful."))
        .then(()=>{window.location.reload();})
        .catch(error=> console.error('Error!', error.message))
    }

    const {setForm, formActive} = useContext(AuthContext)

    const [formData, handleChange] = useFields(INITIAL_DATA)
    
    const handleClick = () =>{
        setForm(!formActive)
    }

    return(
        <div className="overlay flex-column">
            <div id="form-container" className="container center flex-column">
            <CloseIcon action={handleClick}/>
            <form method="POST" id="" className="flex-column " ref={data} onSubmit={handleSubmit}>
                <h2 className="no-margin">Schedule a Demo with Us!</h2>

                <input type="text" name="fname" placeholder="First Name" />
             
                <input required type="text" name="lname" placeholder="Last Name"/>
           
                <input required type="email" name="email" placeholder="Email" />
            
                <input type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required placeholder="Phone Number (123-456-7890)"/>
                <input className="button-style" type="submit" value={'Submit'}/>
            </form>

            </div>
        </div>
    )
    
}

export default ActionForm;