import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Homepage from './pages/Homepage';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <div className="App">
      <AuthProvider>
      <Navbar/>
      <Homepage/>
      </AuthProvider>
    </div>
  );
}

export default App;
