import { createContext, useState } from "react";


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) =>{

    
const [formActive, setForm] = useState(false)







    let contextData = {
        formActive:formActive,
        setForm:setForm
    }

 


    return(
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}