import React, {useState, useEffect, useContext} from "react";
import '../styles/Greeting.css'
import '../styles/Action.css'
import AuthContext from "../context/AuthContext";

const Greeting = () =>{
    const {formActive, setForm} = useContext(AuthContext)

    const [visibleMessages, setVisibleMessages] = useState([]);
    const messages = [
        { text: "Hi, my kitchen sink is clogged. Can you help?", sender: "from-them" },
        { text: "Sure! Have you tried a plunger?", sender: "from-me" },
        { text: "Yes, but it didn’t work.", sender: "from-them" },
        { text: "I can come by tomorrow at 10 AM. Does that work?", sender: "from-me" },
        { text: "Yes, that’s fine.", sender: "from-them" },
        { text: "Great! See you then.", sender: "from-me" },
      ];
    
    const handleClick = () =>{
        setForm(!formActive)
    }

    useEffect(() => {
    let timer;
    if (visibleMessages.length < messages.length) {
        timer = setTimeout(() => {
        setVisibleMessages((prev) => [...prev, messages[prev.length]]);
        }, 2000); // Adjust the delay as needed
    }
    return () => clearTimeout(timer);
    }, [visibleMessages]);

    return(
        <section id="hero-section" className="flex-column">
        <div id="hero-content"  className="flex flex-group container center">
            <div id="intro" className="flex-column align-left container fade-in">
                <h1 id='header'className='max-width text-left'>Grow Your Plumbing Business with AI</h1>
                <p className="intro">
                Take control of your plumbing business with cutting-edge software that streamlines lead management, scheduling, and custom communication-so you can focus on what matters most: growing your business</p>
            
                <div className="flex flex-group button-group">
                    <button onClick={handleClick} className="button-style">Apply Now</button>
           
                </div>
            </div>
            <div id="iphone-convo" className="fade-in">
        
{visibleMessages.map((msg, index) => (
        <p
        
          key={index}
          className={`text-message ${msg.sender} message-visible`}
        >
          {msg.text}
        </p>
      ))}
            </div>
        </div>
        </section>
    )
}

export default Greeting;