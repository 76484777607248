import React from "react";
import prevImg from '../assets/images/preview-img.jpg';

import '../styles/Main.css'

const Main = () =>{
    
    return(
        <section id="main-section" className="">
            <div id="main-content" className="container flex center">
                <div id=""  className="flex-column content fade-in ">
                    
                    <h1>AI Driven Tools, Powered By Real People</h1>
                    <p>Supercharge your operations with Enable Plumbing—a game-changing SaaS platform designed to optimize every aspect of your business. Our software streamlines job scheduling and dispatch, automates invoicing and payment processing, and provides a robust CRM to track customer interactions.</p>

<p>Take advantage of AI-driven analytics that boost your decision-making power, helping you convert more leads and provide outstanding service. Plus, our advanced online booking system and real-time tracking ensure you never miss an opportunity. With integrated PPC, email marketing, and social media management, you’ll elevate your online presence and reach more potential customers—faster than ever before.</p>

<p>Join the plumbing revolution with software that lets you focus on delivering great service while we handle the rest. It’s time to future-proof your business, reduce admin workload, and grow your bottom line with Enable Plumbing.</p>

                </div>
                <div id="" className="prev-img content">
                    <img src={prevImg} className="center" alt="pipeline-img"/>
                </div>

            </div>

        </section>
    )
}

export default Main;