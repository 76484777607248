import React, { useContext } from "react";
import Greeting from "../components/Greeting";
import Main from "../components/Main";
import Features from "../components/Features";
import Action  from "../components/Action";
import "../App.css";
import ActionForm from "../components/Form";

import AuthContext from '../context/AuthContext'

const Homepage = () =>{

    const {formActive} =  useContext(AuthContext)
    return(
    <main className="flex-column align-center">
        <Greeting/>
        <Main/>
        <Features/>
        <Action/>
        {formActive?<ActionForm/>:null}
    </main>)
}

export default Homepage;