import React from "react";
import FeatureBox from "./FeatureBox";
import ad_icon from '../assets/icons/megaphone.png'
import google_add_icon from '../assets/icons/logotype.png'
import email_icon from '../assets/icons/email.png'
import phone_icon from '../assets/icons/phone-call.png'
import bot_icon from '../assets/icons/bot.png'
import dashboard_icon from '../assets/icons/monitor.png'

import "../styles/Features.css";

const features = [
    {
        'icon':ad_icon,
        'title':"Ad Campaign Implementation & Management",
        'detail':"Comprehensive management of your digital advertising campaigns to ensure optimal performance."
    },

    {
        'icon':google_add_icon,
        'title':"Google PPC",
        'detail':"Capture high-intent searches with targeted pay-per-click advertising."

    },

    {
        'icon': email_icon,
        'title':"Email Marketing" ,
        'detail':" Re-engage your current client base with personalized, effective email campaigns"
              
    },
    {
        'icon':phone_icon,
        'title':"Lead/Call Tracking",
        'detail':'Available in select countries to monitor and optimize call conversions'

    },
    {
        'icon':bot_icon,
        'title':'Click Bot Management',
        'detail':'Protect your ads from click fraud and ensure your budget is spent effectively.'
    },
    {
        'icon': dashboard_icon,
        'title':'SaaS Platform',
        'detail':'Use our advanced SaaS backend for managing appointments, customer interactions, and analytics'
    }
]

const Features = () =>{
    return(
        <section id="features" className='flex-column align-center '>
            <div className="container flex-column align-center">
                <h1>What is ENABLE PLUMBING?</h1>
                <p className="">Refining Products, Expanding Markets, Enhancing Support, and Streamlining Operations for Smarter Growth.</p>
            
                <ul id="features" className="my-ul flex align-center">
               
                    {features.map((feat)=>{
                        return(
                            <FeatureBox icon={feat.icon} title={feat.title} detail={feat.detail}/>
                        )
                    })}
                 </ul>
            </div>

        </section>
    )
}

export default Features