import React, { useContext } from "react";
import '../styles/Action.css'
import AuthContext from "../context/AuthContext";
const Action = () =>{

    const {formActive, setForm} = useContext(AuthContext)

    const handleClick = () =>{
        setForm(!formActive)
    }

    return(
        <section id="action-section" className="flex-column align-center">
            <div className="container flex-column align-center">
                <h2 className="no-margin">Ready to grow your business?</h2>
                <p>Implement a new revenue stream that will deliver value to your clients and competitive moat for you.</p>
                <button className="button-6" onClick={handleClick}>Apply Now</button>
            </div>
        </section>
    )
}

export default Action;